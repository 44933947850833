export function getParams(str) {
  let newStr = str.slice(1);
  let json = {};
  let jsonArr = newStr.split('&');
  for (const item of jsonArr) {
    let arr = item.split('=');
    json[arr[0]] = arr[1];
  }
  return json;
}
