import React, { useCallback, useMemo, useState } from 'react';

import ImageViewer from 'components/ImageViewer';
import jump from 'utils/jump';

import styles from './style.module.less';

export default ({ title, description, pictures, style }) => {
  const [curr, setCurr] = useState(null);
  const imagelist = useMemo(() => pictures.map((x) => x.original.src), [
    pictures,
  ]);
  const closeHandler = useCallback(() => setCurr(null), []);
  const address = [
    {
      name: '西溪湿地校区',
      address: '杭州市西湖区文二西路808号西溪壹号23',
    },
    {
      name: '黄龙旗舰校区',
      address: '杭州西湖区黄龙世纪广场B座1层23号',
    },
    {
      name: '大悦城校区',
      address: '杭州拱墅区莫干山路中粮大悦城5F',
    },
    {
      name: '中大银泰城校区',
      address: '杭州市下城区东新路822号中大银泰城2层',
    },
    {
      name: '西溪印象城校区',
      address: '杭州市余杭区五常大道1号一期3楼',
    },
    {
      name: '绿城指教社区音乐中心（杨柳郡校区）',
      address:
        '杭州市上城区彭埠街道九和路660号杨柳郡园三区13幢底商1绿城指教社区音乐中心',
    },
  ];
  return (
    <div id="tech-env" className={styles.container} style={style}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>

        <div className={styles.gallery}>
          {(pictures || []).map(({ thumbnail }, i) => {
            return (
              <span
                key={i}
                className={styles.galleryPreview}
                onClick={() => {
                  jump('#tech-env');
                  setCurr(i);
                }}
              >
                <img
                  className={styles.galleryPic}
                  src={thumbnail.src}
                  style={thumbnail.style}
                />
              </span>
            );
          })}
        </div>

        <ImageViewer
          visible={curr !== null}
          index={curr}
          imgs={imagelist}
          onClose={closeHandler}
        />
      </div>

      <div className={styles.addressBox}>
        {address.map((x, y) => {
          return (
            <div className={styles.item} key={y}>
              <div className={styles.itemLeft}>
                <img
                  src={require('images/icon/point.svg')}
                  className={styles.itemImg}
                />
              </div>
              <div className={styles.itemRight}>
                <div className={styles.itemRightName}>{x.name}</div>
                <div className={styles.itemRightAddress}>{x.address}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
