import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RulesAlertM from 'components/RulesAlertM';
import axios from 'axios';

class FormBannerM extends Component {
  constructor(props) {
    super(props);

    this.state = {
      school: '',
      userName: '',
      checkRules: false,
      showModal: false,
      phone: '',
      phoneCode: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeSchool(e) {
    e.preventDefault();
    this.setState({
      school: e.currentTarget.value,
    });
  }

  handleSubmit() {
    wx.miniProgram.navigateTo({
      url: `/packageA/pages/box/appointCourse/index?classroomId=${this.props.classroomId}&activeItem=1`,
    });
    /*  document.addEventListener('UniAppJSBridgeReady', () => {
      uni.navigateTo({
        url: `packageA/pages/box/appointCourse/index?classroomId=${this.props.classroomId}&activeItem=1`,
      });
    }); */
  }
  render() {
    const { landingImage } = this.props;

    return (
      <Fragment>
        <RulesAlertM
          showModal={this.state.showModal}
          closeModal={() => {
            this.setState({
              showModal: false,
            });
          }}
        />
        <div
          className="form-box"
          style={{ width: '100%', height: '320px' }}
          id="form-box"
        >
          {landingImage ? (
            <div
              className="form-box-banner"
              style={{
                width: '100%',
                height: '250px',
                fontSize: '0px',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src={landingImage}
                alt="landing-image"
              />
            </div>
          ) : null}
          {
            <div
              style={{
                width: '100%',
                backgroundColor: '#fff',
                padding: '10px 0px',
              }}
            >
              <div
                onClick={() => this.handleSubmit()}
                style={{
                  margin: '10px auto',
                  userSelect: 'none',
                  width: '320px',
                  height: '42px',
                  background:
                    'linear-gradient(141deg,rgba(64,67,65,1) 0%,rgba(47,50,49,1) 39%,rgba(42,46,44,1) 100%)',
                  borderRadius: '4px',
                  fontSize: '16px',
                  fontFamily: 'PingFangSC-Light,PingFang SC',
                  fontweight: 300,
                  color: 'rgba(255,255,255,1)',
                  lineHeight: '42px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                立即预约0元体验课
              </div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

FormBannerM.propTypes = {
  landingImage: PropTypes.string,
  discountTitle: PropTypes.string,
  instrument: PropTypes.oneOf(['piano', 'voice']).isRequired,
  customer: PropTypes.oneOf(['adult', 'child']).isRequired,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func,
};

FormBannerM.defaultProps = {
  onSuccess: function () {},
};

export default FormBannerM;
