import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import HeaderM from 'components/HeaderM';
import TeachEnvM from 'components/TeachEnvM/TeachEnvMWX';
import FormBannerM from 'components/FormBannerM/FormBannerMWX';
import FixedBtnM from 'components/FixedBtnM';
import CopyRightM from 'components/CopyRightM';
import useCR from 'utils/useCR';
import useSource from 'utils/useSource';
import track from 'utils/track';
import wexin from 'utils/wexin';
import { getParams } from 'utils/utils';
export default ({ location: { search } }) => {
  // 是否展示版权
  const cr = useCR(search);
  const source = useSource(search, '8');
  const [classroomId, setClassroomId] = useState(getParams(search).classroomId);
  // 埋点
  useEffect(() => {
    track().pv('【指教音乐官网】成人声乐——页面访问');

    // 微信分享
    wexin((wx) => {
      console.log('weixinsdk ready');

      const data = {
        title: 'Finger成人声乐1对1课免费试听',
        desc: '想要自信开口唱的个人主打歌吗？你距离“k歌之王”只有一套课的距离！',
        link: window.location.href,
        imgUrl:
          'https://front.finger66.com/18576CEE-122E-4FDD-BE26-7FB31F23B2A4.png',
        success: function () {},
      };

      wx.updateAppMessageShareData(data);
      wx.updateTimelineShareData(data);
      wx.onMenuShareTimeline(data);
      wx.onMenuShareAppMessage(data);
      wx.onMenuShareQQ(data);
      wx.onMenuShareWeibo(data);
      wx.onMenuShareQZone(data);
    });
  }, []);

  if (cr) {
    return (
      <iframe
        style={{ border: 'none' }}
        width={window.innerWidth}
        height={window.innerHeight}
        src="https://www.finger66.com/activity/teacher-join/#/?source=410000"
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Finger-成人声乐1V1</title>
      </Helmet>
      <HeaderM
        menus={[
          {
            id: '',
            title: '首页',
          },
          {
            id: 'sec-1',
            title: '从小白到歌手',
          },
          {
            id: 'sec-3',
            title: '贴心定制课程',
          },
          {
            id: 'sec-4',
            title: '教学特色',
          },

          {
            id: 'tech-env',
            title: '教学环境',
          },
          {
            id: 'form-box',
            title: '免费预约',
            active: true,
          },
        ]}
        onPhoneClick={() => track().pv('【指教音乐官网】成人声乐——电话咨询')}
      />
      <FormBannerM
        landingImage="https://front.finger66.com/adultVoiceBanner.png"
        discountTitle="499元的成人零基础声乐课"
        instrument="voice"
        customer="adult"
        source={source}
        classroomId={classroomId}
        onSuccess={() => {
          alert('预约成功！');
          track().pv('【指教音乐官网】成人声乐——成功提交信息');
          window.location.reload();
        }}
      />
      <img
        id="sec-1"
        src={require('images/voice/adult/sec-1.png')}
        alt="sec-1"
        style={{
          display: 'table-caption',
          width: '100%',
          background: 'rgb(240, 242, 245)',
        }}
      />
      <img
        id="sec-2"
        src={require('images/voice/adult/sec-2.png')}
        alt="sec-2"
        style={{
          display: 'table-caption',
          width: '100%',
          background: 'rgb(240, 242, 245)',
        }}
      />
      <img
        id="sec-3"
        src={require('images/voice/adult/sec-3.png')}
        alt="sec-3"
        style={{
          display: 'table-caption',
          width: '100%',
          background: 'rgb(240, 242, 245)',
        }}
      />
      <img
        id="sec-4"
        src={require('images/voice/adult/sec-4.png')}
        alt="sec-4"
        style={{
          display: 'table-caption',
          width: '100%',
          background: 'rgb(240, 242, 245)',
        }}
      />

      <TeachEnvM
        title="杭州连锁校区"
        description="专业设计＆施工团队，打造杭城环境一流的音乐教育机构"
        style={{ marginTop: '-6px', paddingBottom: '40px' }}
        pictures={[
          {
            thumbnail: {
              src: require('images/piano/adult/env1.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env1.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env2.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env2.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env3.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env3.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env4.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env4.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env5.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env5.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env6.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env6.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env7.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env7.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env8.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env8.jpg'),
              style: { width: 1980 },
            },
          },
          {
            thumbnail: {
              src: require('images/piano/adult/env9.jpg'),
              style: { maxWidth: 480 },
            },
            original: {
              src: require('images/piano/adult/env9.jpg'),
              style: { width: 1980 },
            },
          },
        ]}
      />
      {cr && <CopyRightM />}
      <FixedBtnM
        showCopyRight={cr}
        onClick={() => track().pv('【指教音乐官网】成人声乐——底部免费预约')}
      />
    </>
  );
};
